<template>
    <div>
        <div id="real-table"></div>
    </div>
</template>

<script>
import axios from 'axios';
import config from '@/config.js';

var tabledata = [];

export default{
    name: 'Status',
    data: () => ({
    }),
    created(){
        let nav_arry_str=[];
        //檢查會員權限
        const apiurl_authMem = config.geturl(config.API.exeAuthMem,{});
        const memlogin_url = config.geturl(config.API.memLogin,{});
        axios.get(apiurl_authMem).then(response => {
            //console.log(response.data);
            if(response.data.isOk){
                let nav_str_ay = response.data.log.split(',');
                nav_str_ay.forEach(function(item){
                    nav_arry_str.push(parseInt(item));
                });
                if(!nav_arry_str.includes(8)){
                    alert("您沒有該頁權限，請重新登入");
                    location.href=memlogin_url;
                }
            }else{
                alert("您沒有該頁權限，請重新登入");
                location.href=memlogin_url;
            }
        });
    },
    mounted(){
        $(function() {
            $(".nav_mainTitle").text("上傳狀況");

            const apiurl=config.geturl(config.API.upstation,{});
            axios.get(apiurl).then(response => {
                if(response.data !== null && response.data !== ""){
                    //tabledata = response.data;
                    //console.log(tabledata);

                    var new_json=[];


                    $.each(response.data, function(i, item) {
                        //alert(item.PageName);
                        
                        let real_count=(item.count/item.total_minutes)*100;

                        new_json.push({
                            "id":item.id,
                            "device":item.device,
                            "env": envText(item.env),
                            "nearest_epa":item.nearest_epa,
                            "status":item.status,
                            "count":item.count + "/" + item.total_minutes,
                            "real_up":real_count,
                            "time":item.time
                        });
                    });

                    //console.log(new_json);
                    tabledata = new_json;

                    var table = new Tabulator("#real-table", {
                        height:"auto",
                        locale:"en-gb",
                        langs:{
                            "en-gb":{
                                "pagination":{
                                    "page_size":"頁數", //label for the page size select element
                                    "first":"第一頁", //text for the first page button
                                    "first_title":"第一頁", //tooltip text for the first page button
                                    "last":"最後一頁",
                                    "last_title":"最後一頁",
                                    "prev":"上一頁",
                                    "prev_title":"上一頁",
                                    "next":"下一頁",
                                    "next_title":"下一頁",

                                },
                            }
                        },
                        data:tabledata,           //load row data from array
                        layout:"fitColumns",      //fit columns to width of table
                        responsiveLayout:"hide",  //hide columns that dont fit on the table
                        tooltips:true,            //show tool tips on cells
                        addRowPos:"top",          //when adding a new row, add it to the top of the table
                        history:true,             //allow undo and redo actions on the table
                        pagination:"local",       //paginate the data
                        paginationSize:50,         //allow 7 rows per page of data
                        paginationSizeSelector:true,
                        //paginationCounter:"rows", //display count of paginated rows in footer
                        movableColumns:true,      //allow column order to be changed
                        resizableRows:true,       //allow row order to be changed
                        columns:[
                            {title:"編號", field:"id", sorter:"string", headerFilter:true, headerFilterPlaceholder:"篩選編號"},
                            {title:"運行計畫", field:"env", sorter:"string", headerFilter:true, headerFilterPlaceholder:"計畫名稱"},
                            {title:"測站名稱", field:"device", sorter:"string", headerFilter:true, headerFilterPlaceholder:"測站名稱"},
                            {title:"狀態", field:"status", sorter:"string"},
                            {title:"實際/應具備筆數", field:"count", sorter:"string"},
                            {title:"上傳率", field:"real_up", sorter:"string"},
                            {title:"最近更新時間", field:"time", sorter:"string"},
                        ],
                        
                        //autoColumns:true,
                    });
                }

            });
            


        })
    }
}

function envText(num){
    var final_txt="";
    if(typeof(num) !="undefined" && parseInt(num) == 0){
        final_txt = "測試站";
    }
    return final_txt;
}

</script>